import styled from "styled-components";
import {
  Button,
  LinkButton,
  GridContainer
} from "@lewisblackwood/component-library";
import { ContentSection } from "../components/layout";
import media from "./Media";

export const ContactCard = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colours[props.colour].lightest};
  border-radius: 5px;
  height: 150px;
  cursor: pointer;
  transition: 0.8s cubic-bezier(0.2, 0.82, 0.2, 1);
  vertical-align: top;
  padding: 0 ${props => props.theme.spacing.small};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px ${props => props.theme.hoverColours.primary};
  }
`;

export const FreeTrialButton = styled(LinkButton)`
  display: inline-block;
  margin-right: ${props => props.theme.spacing.medium};

  ${media.small`
    margin-bottom: ${props => props.theme.spacing.medium};
    margin-right: 0;
  `}
`;

export const SubscribeButton = styled(Button)`
  margin-left: ${props => props.theme.spacing.xsmall};

  @media only screen and (max-width: 443px) {
    margin-left: 0;
    margin-top: ${props => props.theme.spacing.xsmall};
  }
`;

export const ContactSection = styled(ContentSection)`
  margin-bottom: ${props => props.theme.spacing.large};

  ${media.medium`
    margin-bottom: ${props => props.theme.spacing.medium};
  `};

  ${media.small`
    margin-bottom: ${props => props.theme.spacing.small};
  `};
`;

export const ContactGridContainer = styled(GridContainer)`
  grid-gap: ${props => props.theme.spacing.medium};

  ${media.small`
    grid-gap: ${props => props.theme.spacing.small};
  `};
`;
