import React from "react";
import { ContentSection } from "../layout";
import { H2, Text, StyledLink } from "@lewisblackwood/component-library";
import { ContactCard } from "../../styledComponents/Contact";
import TechCrunchLogo from "../../images/tech-crunch-logo.svg";

const Investors = () => (
  <ContentSection>
    <H2 center mb="xlarge" size="xlarge" sizeMobile="xlarge">
      Our Investors
    </H2>
    <div style={{ maxWidth: "640px", margin: "auto" }}>
      <Text size="xlarge" sizeMobile="large" center mb="xxlarge">
        We’re backed by{" "}
        <StyledLink
          href="https://www.globalfounders.vc/"
          colour="purple"
          size="xlarge"
          sizeMobile="large"
          bold
        >
          Global Founders Capital
        </StyledLink>
        , the venture arm of Rocket Internet. They invest in the kinds of
        companies we are excited to have as customers.
      </Text>

      <ContactCard
        colour="green"
        style={{ width: "100%" }}
        onClick={() =>
          window.open("https://techcrunch.com/2018/04/18/personably/", "_blank")
        }
      >
        <img
          src={TechCrunchLogo}
          alt="TechCrunch"
          width={40}
          style={{ marginBottom: "20px" }}
        />
        <Text size="xlarge" sizeMobile="large" mb="none" center>
          Read about us on TechCrunch
        </Text>
      </ContactCard>
    </div>
  </ContentSection>
);

export default Investors;
