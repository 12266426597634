import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import TeamPhotoImg from "../../images/personably-team-photo.jpg";
import media from "../../styledComponents/Media";

export const TeamPhoto = () => (
  <StyledTeamPhoto>
    <ScrollAnimation animateOnce="true" animateIn="fadeAndRiseUp">
      <img src={TeamPhotoImg} alt="Photo of the team" />
    </ScrollAnimation>
  </StyledTeamPhoto>
);

const StyledTeamPhoto = styled.div`
  width: calc(100vw - 32px);
  max-width: 720px;
  grid-row: 1 / 4;
  grid-column: 2;
  justify-self: center;

  img {
    border-radius: 10px;
    width: 100%;
  }

  ${media.small`
    max-width: 100vw;
  `};
`;

export const PushContentDown = styled.div`
  height: 240px;

  @media only screen and (max-width: 570px) {
    height: 190px;
  }

  @media only screen and (max-width: 420px) {
    height: 120px;
  }
`;

export const SocialMedia = styled.div`
  margin-top: auto;

  img {
    cursor: pointer;
  }
`;
