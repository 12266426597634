import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Hero from "../components/about/Hero";
import Why from "../components/about/Why";
import Team from "../components/about/Team";
import Investors from "../components/about/Investors";
import Blog from "../components/about/Blog";

const About = () => (
  <Layout>
    <Helmet>
      <title>About Us</title>
      <meta
        name="description"
        content="At Personably, we're building tools to help you create the strongest employee onboarding experience at scale."
      />
    </Helmet>
    <Hero />
    <Why />
    <Team />
    <Investors />
    <Blog />
  </Layout>
);

export default About;
