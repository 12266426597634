import React from "react";
import { FlexSection, Title } from "../layout";
import { H1, H2, Text } from "@lewisblackwood/component-library";

const Hero = () => (
  <FlexSection>
    <Title style={{ maxWidth: 960 }}>
      <H1
        size="small"
        sizeMobile="small"
        mb="xsmall"
        colour="grey"
        center
        style={{ fontWeight: 400 }}
      >
        About us
      </H1>
      <H2 size="xxlarge" sizeMobile="large" center>
        Helping teams deliver outstanding employee experience
      </H2>
      <Text size="xlarge" sizeMobile="large" center style={{ maxWidth: 660 }}>
        If you’re running the people team or trying to give new starters a great
        first impression, we’re building Personably for&nbsp;you.
      </Text>
    </Title>
  </FlexSection>
);

export default Hero;
