import React, { Component } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { Curve, FlexSection, FillMargin, FlexItem } from "../layout";
import { BlogCard } from "../../styledComponents/Blog";
import { H2, Button, WrapFlexContainer } from "@lewisblackwood/component-library";
import placeholder from "../../images/article-placeholder.png";

class BlogPostCard extends Component {
  getImageUrl = url => {
    if (!url) {
      return placeholder;
    } else if (url.includes("http")) {
      return url;
    } else {
      return `https://blog.personably.co${url}`;
    }
  };

  render() {
    const { post } = this.props;

    return (
      <FlexItem>
        <BlogCard
          title={post.frontmatter.title}
          image={this.getImageUrl(post.frontmatter.feature_image)}
          tag={post.frontmatter.primary_tag_name}
          url={`/blog/${post.fields.slug}`}
        />
      </FlexItem>
    );
  }
}

class Blog extends Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <>
        <Curve colour="grey" />
        <FillMargin colour="grey" />

        <FlexSection colour="grey">
          <H2 center mb="xlarge" size="xlarge" sizeMobile="xlarge">
            Latest from the blog
          </H2>
          {postEdges.length > 0 && (
            <WrapFlexContainer
              justifyContent="center"
              style={{ margin: "0 -20px 20px -20px" }}
              width="calc(100% + 20px)"
            >
              <BlogPostCard
                key={postEdges[0].node.id}
                post={postEdges[0].node}
                animateIn="fadeAndRiseUp"
              />
              <BlogPostCard
                key={postEdges[1].node.id}
                post={postEdges[1].node}
                animateIn="fadeAndRiseUpSlow"
              />
              <BlogPostCard
                key={postEdges[2].node.id}
                post={postEdges[2].node}
                animateIn="fadeAndRiseUpSlower"
              />
            </WrapFlexContainer>
          )}

          <Link to="/blog">
            <Button
              secondary
              height="48px"
              width="120px"
              size="large"
              bold
              alt="See more from the blog"
            >
              See more
            </Button>
          </Link>
        </FlexSection>
        <FillMargin right colour="grey" />
        <Curve flipped colour="grey" />
      </>
    );
  }
}

// const BlogQuery = props => (
//   <StaticQuery
//     query={graphql`
//       query GhostRecentPostQuery {
//         allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 3) {
//           edges {
//             node {
//               id
//               slug
//               title
//               feature_image
//               primary_tag {
//                 id
//                 name
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={data => <Blog data={data} {...props} />}
//   />
// );

const BlogQuery = props => (
  <StaticQuery
    query={graphql`
      query GhostRecentPostQuery {
        allMarkdownRemark(sort: {fields: frontmatter___published_at, order: DESC}, limit: 3) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                feature_image
                description
                published_at
                updated_at
                primary_tag_name
                primary_author_name
                primary_author_twitter
              }
              html
            }
          }
        }
      }
    `}
    render={data => <Blog data={data} {...props} />}
  />
);

export default BlogQuery;
