import React from "react";

// Components
import { FlexContainer, H2, Text } from "@lewisblackwood/component-library";
import {
  CircleBackground,
  FlexSection,
  GridImageSection,
  ImageTextSection,
  TextSection,
  GridSection
} from "../layout";
import { ImageAnimated } from "../../styledComponents/product/Image";
import { ContentCard, Icon } from "../../styledComponents/Common";

// images
import UserIcon from "../../images/icon-user.svg";
import GreenUserIcon from "../../images/icon-user-green.svg";
import AboutIllustrationOne from "../../images/illustration-about-1.svg";
import AboutIllustrationTwo from "../../images/illustration-about-2.svg";
import CalendarIcon from "../../images/icon-calendar.svg";
import CheckListIcon from "../../images/icon-check-square.svg";

const Why = () => (
  <>
    <ImageTextSection style={{ marginTop: 40 }}>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large" colour="purple">
          Employee onboarding is often neglected and broken
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Companies spend a lot of time hiring the best employees but then
          preparing for and training them up becomes an afterthought.
        </Text>
        <Text size="xlarge" sizeMobile="large">
          Finding out what the wider team do, how to contribute, and how to be
          successful becomes a huge challenge.
        </Text>
      </TextSection>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated
          fitMobile
          src={AboutIllustrationOne}
          column="3 / 3"
          row="3 / 3"
        />
        <CircleBackground colour="purple" column="2 / 5" row="2 / 5" />
      </GridImageSection>
    </ImageTextSection>

    <GridSection columns="1fr auto 1fr" rows="1fr auto 1fr">
      <FlexSection
        alignItems="start"
        flexDirection="row"
        justifyContent="center"
        wrap
        column="1 / 4"
        row="2"
        style={{ margin: -10 }}
      >
        <ContentCard
          title="33% of employees"
          text="know whether they want to stay long&#8209;term at a company within one week on the job."
          reference="Onboard Top Talent - LinkedIn Learning"
          refColour="green"
          refLink="https://learning.linkedin.com/content/dam/me/learning/EMW/lil-guide-leverage-learning-onboard-top-talent.pdf"
        >
          <FlexContainer justifyContent="center" style={{ marginBottom: 20 }}>
            <Icon size="large" image={UserIcon} />
            <Icon size="large" image={GreenUserIcon} />
            <Icon size="large" image={UserIcon} />
          </FlexContainer>
        </ContentCard>
        <ContentCard
          title="69% more likely to stay up to three&nbsp;years"
          text="when new employees attend a well&#8209;structured onboarding program."
          reference="SHRM, Onboarding New Employees: Maximizing&nbsp;Success"
          refColour="green"
          refLink="https://www.shrm.org/foundation/ourwork/initiatives/resources-from-past-initiatives/Documents/Onboarding%20New%20Employees.pdf"
        >
          <FlexContainer justifyContent="center" style={{ marginBottom: 20 }}>
            <Icon size="large" image={CalendarIcon} />
          </FlexContainer>
        </ContentCard>
        <ContentCard
          title="New employees 25% faster at becoming effective"
          text="when they follow their onboarding checklist."
          reference="‘Work Rules’, Laszlo Bock"
          refColour="green"
          refLink="https://www.workrules.net/"
        >
          <FlexContainer justifyContent="center" style={{ marginBottom: 20 }}>
            <Icon size="large" image={CheckListIcon} />
          </FlexContainer>
        </ContentCard>
      </FlexSection>

      <CircleBackground colour="green" column="2" row="1 / 4" />
    </GridSection>

    <ImageTextSection>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated
          fitMobile
          src={AboutIllustrationTwo}
          column="3 / 3"
          row="3 / 3"
        />
        <CircleBackground colour="pink" column="2 / 5" row="2 / 5" />
      </GridImageSection>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large" colour="pink">
          Starting right means someone is more likely to stay
        </H2>
        <Text size="xlarge" sizeMobile="large">
          If you’re hiring ambitious individuals ensuring they can be productive
          and deliver value is the key to retention.
        </Text>
        <Text size="xlarge" sizeMobile="large">
          At Personably, we’ve built the platform to help you create the
          strongest employee experience at scale.
        </Text>
      </TextSection>
    </ImageTextSection>
  </>
);

export default Why;
