import React from "react";
import { Curve, FillMargin, FlexSection, GridSection } from "../layout";
import { H2, Text } from "@lewisblackwood/component-library";
import { ProfileCard } from "../../styledComponents/Common";
import {
  TeamPhoto,
  PushContentDown,
  SocialMedia
} from "../../styledComponents/about/Team";

// images
import Lewis from "../../images/team-lewis.jpg";
import Katerina from "../../images/team-katerina.jpg";
import Ruth from "../../images/team-ruth.jpg";
import Jesse from "../../images/team-jesse.jpg";
import Jacob from "../../images/team-jacob.jpg";
import Alexandra from "../../images/team-alexandra.jpg";

const Team = () => (
  <>
    <H2
      size="xlarge"
      sizeMobile="large"
      mb="xlarge"
      center
      style={{ gridColumn: "2" }}
    >
      Our Team
    </H2>
    <GridSection
      column="1 / 4"
      rows="auto auto 40px 40px auto auto auto"
      columns="minmax(32px,1fr) minmax(auto,1400px) minmax(32px,1fr)"
      columnsMobile="16px calc(100vw - 32px) 16px"
      fullWidth
    >
      <TeamPhoto />
      <Curve colour="purple" row="2" />
      <FillMargin
        colour="purple"
        row="3 / 5"
        style={{ gridColumn: "1 / 4", zIndex: "-1" }}
      />
      <FillMargin colour="purple" column="1" row="5" />
      <FlexSection colour="purple" column="2" row="5">
        <Text
          size="xlarge"
          sizeMobile="large"
          center
          mb="xlarge"
          style={{ maxWidth: 660 }}
        >
          Personably was founded in 2017 by Katerina and Lewis, after they
          started seeing the importance of onboarding at the fast-growing start
          ups they joined.
        </Text>
      </FlexSection>
      <FillMargin right colour="purple" column="3" row="5" />

      <FillMargin colour="purple" column="1" row="6" />
      <FlexSection colour="purple" column="2" row="6">
        <FlexSection
          alignItems="start"
          flexDirection="row"
          justifyContent="center"
          wrap
          style={{ margin: -10 }}
        >
          <ProfileCard
            team
            image={Lewis}
            name="Lewis"
            role="CTO & Co-founder"
            info="Currently running all things tech, customer success and product related. He started out at GoCardless and graduated from University of Cambridge with an Economics degree."
            github="https://github.com/lewisblackwood"
            linkedin="https://www.linkedin.com/in/lewisblackwood/"
          />
          <ProfileCard
            team
            image={Katerina}
            name="Katerina"
            role="CEO & Co-founder"
            info="Running all things sales, comms and company related. She started at Crowdcube before freelancing as a full-stack developer. Graduating from the University of Cambridge with a law degree."
            twitter="https://twitter.com/KatAlexPas"
            linkedin="https://www.linkedin.com/in/katerinapascoulis/"
          />
          <ProfileCard
            team
            image={Ruth}
            name="Ruth"
            role="Software Engineer"
            info="The first employee at Personably, joining from JustGiving and before that freelanced as a developer. Graduated from the University of Exeter with a Maths and Philosophy degree."
            github="https://github.com/rug1"
            linkedin="https://www.linkedin.com/in/ruth-uwemedimo-694228a1/"
          />
          <ProfileCard
            team
            image={Jesse}
            name="Jesse"
            role="Product Designer"
            info="Joined from ASOS, having previously worked for ITV and HAVAS Lynx, with over five years experience."
            twitter="https://twitter.com/jessepayne27"
            linkedin="https://www.linkedin.com/in/jessepayne27/"
          />
          <ProfileCard
            team
            image={Jacob}
            name="Jacob"
            role="Software Engineer"
            info="Joined from GoCardless where he worked across Operations and Engineering for five years."
            github="https://github.com/jacobpgn"
            linkedin="https://www.linkedin.com/in/jacobpgn/"
          />
          <ProfileCard
            team
            image={Alexandra}
            name="Alexandra"
            role="Sales Associate"
            info="Joined from UN Women in the Dominican Republic. Graduated from University of Nottingham with a Languages & History degree. She speaks French and Spanish."
            twitter="https://twitter.com/alex_omahony1"
            linkedin="https://www.linkedin.com/in/alexandra-o-mahony-9b5462b6/"
          />
        </FlexSection>
      </FlexSection>
      <FillMargin right colour="purple" column="3" row="6" />
      <Curve flipped colour="purple" row="7" />
    </GridSection>
  </>
);

export default Team;
